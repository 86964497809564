<template>
    <div id="v-pills-jobdetails" role="tabpanel" aria-labelledby="v-pills-jobdetail-tab">
      <div class="col content myJobs" id="myjobs">
        <ul class="nav nav-pills mb-3 mfont" id="pills-tab" role="tablist">
          <li class="nav-item col-4" role="presentation">
            <Span class="nav-link text-center" :class="{'active': tab == 'recommended-jobs'}"  id="pills-recommend-tab" data-toggle="pill" role="tab" aria-controls="pills-recommend" aria-selected="true" @click="tab='recommended-jobs'"><h1>Recommended jobs </h1><span class="badge badge-primary notify">{{jobs.length}}</span></Span>
          </li>
          <li class="nav-item text-center col-4 mfont" role="presentation">
            <Span class="nav-link" id="pills-applied-tab" :class="{'active': tab == 'applied-jobs'}" data-toggle="pill" role="tab" aria-controls="pills-applied" aria-selected="false" @click="tab='applied-jobs'"><h2>Applied jobs</h2></Span>
          </li>
          <li class="nav-item text-center col-4 mfont"  role="presentation" v-if="institute_jobs.length > 0">
            <Span class="nav-link" id="pills-institute-tab" :class="{'active': tab == 'institute-jobs'}" data-toggle="pill" role="tab" aria-controls="pills-institute" aria-selected="false" @click="tab='institute-jobs'"><h2>Institute jobs</h2><span class="badge badge-primary notify">{{institute_jobs.length}}</span></Span>
          </li>
           <li class="nav-item text-center col-4 mfont"  role="presentation" v-if="college_jobs.length > 0">
            <Span class="nav-link" id="pills-college-tab" :class="{'active': tab == 'college-jobs'}" data-toggle="pill" role="tab" aria-controls="pills-college" aria-selected="false" @click="tab='college-jobs'"><h2>College jobs</h2><span class="badge badge-primary notify">{{college_jobs.length}}</span></Span>
          </li>
        </ul>
        <div class="tab-content  jobDetailProfile" id="pills-tabContent">
          <div class="" id="pills-recommend" role="tabpanel" aria-labelledby="pills-recommend-tab" v-if="tab == 'recommended-jobs'">
            <div v-if="jobs_loading">
              <div class="card">
                <div class="card-body jobBox">
                  <content-loader viewBox="0 0 476 124" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="jobs_loading">
                    <rect x="0" y="8" rx="3" ry="3" width="150" height="10" />
                    <rect x="0" y="30" rx="3" ry="3" width="120" height="6" />
                    <rect x="0" y="45" rx="3" ry="3" width="105" height="6" />
                    <rect x="0" y="60" rx="3" ry="3" width="115" height="6" />
                    <rect x="0" y="75" rx="3" ry="3" width="110" height="6" />
                    <rect x="0" y="90" rx="3" ry="3" width="130" height="6" />
                    <rect x="300" y="110" rx="3" ry="3" width="110" height="6" />
                  </content-loader>
                </div>
              </div>
            </div>
            <div class="row" v-if="jobs.length&& !jobs_loading">
              <div class="col-md-12 mb-2" v-for="job in jobs" :key="job.job_id">
                <div class="card">
                  <div class="card-body jobBox">
                    <div class="row">
                      <div class="col-md-12">
                        <a class="mob-applay-btn m-show" :href="'/jobs/'+job.param"></a>
                        <h6 class="jobTitle ">
                          <a class="text-decoration-none" :href="'/jobs/'+job.param">{{job.job_designation}}</a>
                        </h6>
                          <p v-if="job.company_name">
                            <span class="jobIcon"><img :src="$S3_url+'/Assets/images/company.svg'" alt="company name" title="company name" width="18"></span>
                            {{job.company_name}}
                          </p>
                          <p v-if="job.courses"> 
                            <span class="jobIcon"><img :src="$S3_url+'/Assets/images/collage.svg'" alt="education" title="education" width="23"></span>
                            <span v-for="(course , index) in job.courses" :key="index">
                              {{course.name}}<span v-if="job.courses.length != index+1">,</span></span>
                          </p>
                          <p v-if="job.cities">
                            <span class="jobIcon"><img :src="$S3_url+'/Assets/images/location.svg'" alt="location" title="location" width="22"></span>
                            <span v-for="(city, index) in job.cities" :key="index">
                              {{city}}<span v-if="job.cities.length != index+1">,</span></span>
                          </p>
                          <p  v-if="!job.salary_flag &&(job.salary_range_from || job.salary_range_to)">
                            <span class="jobIcon"><img :src="$S3_url+'/Assets/images/money.svg'" alt="salary" title="salary" width="21"></span>
                              <span v-if="job.salary_range_from">Rs. </span>{{job.salary_range_from}}<span v-if="job.salary_range_from&&job.salary_range_to">-</span><span v-if="job.salary_range_to">Rs. </span>{{job.salary_range_to}} per month
                          </p>
                          <p>
                            <span class="jobIcon"><img :src="$S3_url+'/Assets/images/calander.svg'" alt="last date to apply" title="Last date to apply" width="20"></span>
                            Last date to apply: {{job.last_date}} </p> 
                          <p class="postDate"  v-if="job.date_of_posting">{{ calculatePostDate(job.date_of_posting) }}
                          </p>
                        <span v-if="job.share_times == 1" class="ml-4"><small class="text-secondary badge">Shared by your institute</small></span>
                        <span v-if="job.college_share_times == 1" class="ml-4"><small class="text-secondary badge">Shared by your college</small></span>

                          <div class="jobButton  m-hide">
                            <a class="btn btn-outline-primary mobright" :href="'/jobs/'+job.param"><span v-if="!job.applied">View & Apply</span><span v-else>View</span></a>
                          </div>
                      </div>
                    </div>
                  </div> 
                </div> 
              </div>     
              <div class="col lastest-page-m">
                <div class="container">
                  <div class="row text-right">
                    <div class="col-lg-12 col-md-4 col-sm-4 pt-0 pb-0">
                      <a class="text-decoration-none btn btn-outline-primary alljobBtn" href="/jobs"> View all jobs</a>
                    </div>
                  </div>
                </div>
              </div>   
            </div>
          </div>
          <div class="" id="pills-applied" role="tabpanel" aria-labelledby="pills-applied-tab" v-if="tab == 'applied-jobs'">
            <div class="row p-4">
              <div v-if="appliedjobs_loading">
                <content-loader viewBox="0 0 476 124" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="appliedjobs_loading">
                  <rect x="0" y="8" rx="3" ry="3" width="150" height="10" />
                  <rect x="0" y="30" rx="3" ry="3" width="120" height="6" />
                  <rect x="0" y="45" rx="3" ry="3" width="105" height="6" />
                </content-loader>
              </div>
              <div class="col" v-if="appliedJobs.length && !appliedjobs_loading">
                <p style="font-size:10px;">Note: Last 10 jobs applied by you</p>
                <div class="card shadow w-100 mb-5" v-for="applied_job in appliedJobs" :key="applied_job.job_id">
                  <div class="card-body">
                    <h6 class="jobTitle"><a class="text-decoration-none" style="cursor: pointer;" :href="'/jobs/'+applied_job.param">{{applied_job.job.job_designation}}</a></h6>
                    <p class="text-dark"><strong>{{applied_job.job.company_name}}</strong></p>
                    <div>Application status : 
                      <span class="md-step-title" v-if="applied_job.status==0">Applied</span>
                      <span class="md-step-title" v-if="applied_job.status==1">Screened</span>
                      <span class="md-step-title" v-if="applied_job.status==2">Interviewed</span>
                      <span class="md-step-title" v-if="applied_job.status==3">Offered</span>
                      <span class="md-step-title" v-if="applied_job.status==4">Joined</span>
                    </div>
                    <div id="myDIVstat" style="display: none">
                    <div class="row">
                      <div class="md-stepper-horizontal orange">
                        <div class="md-step active">
                          <div class="md-step-circle"><span>1</span></div>
                          <div class="md-step-title">Applied</div>
                          <div class="md-step-bar-left"></div>
                          <div class="md-step-bar-right"></div>
                        </div>
                        <div class="md-step">
                          <div class="md-step-circle"><span>2</span></div>
                          <div class="md-step-title">Screened</div>
                          <div class="md-step-bar-left"></div>
                          <div class="md-step-bar-right"></div>
                        </div>
                        <div class="md-step">
                          <div class="md-step-circle"><span>3</span></div>
                          <div class="md-step-title">Interviewed</div>
                          <div class="md-step-bar-left"></div>
                          <div class="md-step-bar-right"></div>
                        </div>
                        <div class="md-step">
                          <div class="md-step-circle"><span>4</span></div>
                          <div class="md-step-title">Offered</div>
                          <div class="md-step-bar-left"></div>
                          <div class="md-step-bar-right"></div>
                        </div>
                        <div class="md-step">
                          <div class="md-step-circle"><span>5</span></div>
                          <div class="md-step-title">Joined</div>
                          <div class="md-step-bar-left"></div>
                          <div class="md-step-bar-right"></div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col" v-else>
                <div class="container text-center mt-5 mb-5" style="color:#afafaf;">
                  <h3><strong> NO JOBS APPLIED YET </strong></h3>
                </div>
              </div>
            </div>
          </div>
          <div class="" id="pills-institute" role="tabpanel" aria-labelledby="pills-institute-tab" v-if="tab == 'institute-jobs'">
            <div v-if="insti_jobs_loading">
              <div class="card">
                <div class="card-body jobBox">
                  <content-loader viewBox="0 0 476 124" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="insti_jobs_loading">
                    <rect x="0" y="8" rx="3" ry="3" width="150" height="10" />
                    <rect x="0" y="30" rx="3" ry="3" width="120" height="6" />
                    <rect x="0" y="45" rx="3" ry="3" width="105" height="6" />
                    <rect x="0" y="60" rx="3" ry="3" width="115" height="6" />
                    <rect x="0" y="75" rx="3" ry="3" width="110" height="6" />
                    <rect x="0" y="90" rx="3" ry="3" width="130" height="6" />
                    <rect x="300" y="110" rx="3" ry="3" width="110" height="6" />
                  </content-loader>
                </div>
              </div>
            </div>
            <div class="row" v-if="institute_jobs.length&& !insti_jobs_loading">
              <div class="col-md-12 mb-2" v-for="job in institute_jobs" :key="job.job_id">
                <div class="card">
                  <div class="card-body jobBox">
                    <div class="row">
                      <div class="col-md-12">
                        <a class="mob-applay-btn m-show" target="_blank" :href="'/job/'+job.job_url"></a>
                        <h6 class="jobTitle ">
                          <a class="text-decoration-none" target="_blank" :href="'/job/'+job.job_url">{{job.job_designation}}</a>
                        </h6>
                          <p v-if="job.company_name">
                            <span class="jobIcon"><img :src="$S3_url+'/Assets/images/company.svg'" alt="company name" title="company name" width="18"></span>
                            {{job.company_name}}
                          </p>
                          <p v-if="job.cities">
                            <span class="jobIcon"><img :src="$S3_url+'/Assets/images/location.svg'" alt="location" title="location" width="22"></span>
                            <span>{{job.cities}}</span>
                          </p>
                          <p>
                            <span class="jobIcon"><img :src="$S3_url+'/Assets/images/calander.svg'" alt="last date to apply" title="Last date to apply" width="20"></span>
                            Last date to apply: {{job.last_date}} </p> 
                          <p class="postDate"  v-if="job.date_of_posting">{{ calculatePostDate(job.date_of_posting) }}
                          </p>
                        
                          <div class="jobButton  m-hide">
                            <a class="btn btn-outline-primary mobright" :href="'/job/'+job.job_url" target="_blank"><span>View & Apply</span></a>
                          </div>
                      </div>
                    </div>
                  </div> 
                </div> 
              </div>     
            </div>
            <div class="col" v-else>
                <div class="container text-center mt-5 mb-5" style="color:#afafaf;">
                  <h3><strong> NO JOBS SHARED YET </strong></h3>
                </div>
              </div>
          </div>
          <div class="" id="pills-college" role="tabpanel" aria-labelledby="pills-college-tab" v-if="tab == 'college-jobs'">
            <div v-if="college_jobs_loading">
              <div class="card">
                <div class="card-body jobBox">
                  <content-loader viewBox="0 0 476 124" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="insti_jobs_loading">
                    <rect x="0" y="8" rx="3" ry="3" width="150" height="10" />
                    <rect x="0" y="30" rx="3" ry="3" width="120" height="6" />
                    <rect x="0" y="45" rx="3" ry="3" width="105" height="6" />
                    <rect x="0" y="60" rx="3" ry="3" width="115" height="6" />
                    <rect x="0" y="75" rx="3" ry="3" width="110" height="6" />
                    <rect x="0" y="90" rx="3" ry="3" width="130" height="6" />
                    <rect x="300" y="110" rx="3" ry="3" width="110" height="6" />
                  </content-loader>
                </div>
              </div>
            </div>
            <div class="row" v-if="college_jobs.length&& !insti_jobs_loading">
              <div class="col-md-12 mb-2" v-for="job in college_jobs" :key="job.job_id">
                <div class="card">
                  <div class="card-body jobBox">
                    <div class="row">
                      <div class="col-md-12">
                        <a class="mob-applay-btn m-show" target="_blank" :href="'/job/'+job.job_url"></a>
                        <h6 class="jobTitle ">
                          <a class="text-decoration-none" target="_blank" :href="'/job/'+job.job_url">{{job.job_designation}}</a>
                        </h6>
                          <p v-if="job.company_name">
                            <span class="jobIcon"><img :src="$S3_url+'/Assets/images/company.svg'" alt="company name" title="company name" width="18"></span>
                            {{job.company_name}}
                          </p>
                          <p v-if="job.cities">
                            <span class="jobIcon"><img :src="$S3_url+'/Assets/images/location.svg'" alt="location" title="location" width="22"></span>
                            <span>{{job.cities}}</span>
                          </p>
                          <p>
                            <span class="jobIcon"><img :src="$S3_url+'/Assets/images/calander.svg'" alt="last date to apply" title="Last date to apply" width="20"></span>
                            Last date to apply: {{getDate(job.last_date)}} </p> 
                          <p class="postDate"  v-if="job.date_of_posting">{{job.date_of_posting  | moment("from", "now", true) }} ago
                          </p>
                        
                          <div class="jobButton  m-hide">
                            <a class="btn btn-outline-primary mobright" :href="'/job/'+job.job_url" target="_blank"><span>View & Apply</span></a>
                          </div>
                      </div>
                    </div>
                  </div> 
                </div> 
              </div>     
            </div>
            <div class="col" v-else>
                <div class="container text-center mt-5 mb-5" style="color:#afafaf;">
                  <h3><strong> NO JOBS SHARED YET </strong></h3>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import moment from 'moment';
import validation from '../libraries/validFormat' ;
import Actions from '../libraries/apiActions.js';
import '../assets/hire.css';
import Vue from 'vue'
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'JobDetails',
  components: {
    ContentLoader,
  },
  data(){
    return{
      action: new Actions(),
      validate: new validation(),
      jobs:[],
      rec_jobs:[],
      all_jobs:[],
      job_flag:0,
      pages:[],
      page:1,
      limit:10,
      locations:[],
      filter:{
        job_role:null,
        city_id:null,
        limit: 0
      },
      institute_jobs: [],
      college_jobs: [],
      institute_meta: {
        limit: 10,
        offset: 0,
        total: 0
      },
      college_meta: {
        limit: 10,
        offset: 0,
        total: 0
      },
      user:null,
      appliedJobs:[],
      tab:'recommended-jobs',
      jobs_loading:true,
      insti_jobs_loading:false,
      college_jobs_loading:false,
      appliedjobs_loading:true,
    }
  },
  beforeMount(){
    this.action.getUser(this.$cookies.get("hire-seeker").token).then(res => { 
      if(res){
        this.user= res.user; 
        this.filter.job_role = (this.user.careerPreferencesDetail?.fst_prefered_role)?this.user.careerPreferencesDetail?.fst_prefered_role:null;
        this.filter.city_id = (this.user.personal_details?.city_id)?this.user.personal_details?.city_id:null;
        this.filter.user_id = this.user.id;  
        this.filter.limit = this.limit;
        this.getJobList();
        this.InstitutePrivateJobs();
        this.CollegePrivateJobs();
      }
    });
    this.getStates();
  },
  methods:{
    calculatePostDate(date) {
      const dateObject = new Date(date * 1000);
      return moment(dateObject).fromNow();
    },
    async getJobList() {
      try {
        const response = await this.action.getJobList({
          limit: this.filter.limit,
          user_id: this.filter.user_id
        });
        
        this.rec_jobs = response.jobs;
        this.jobs = this.rec_jobs;

        if (response.meta.total < this.limit) {
          this.filter.limit = this.limit - response.meta.total;
          this.filter.job_role = null;
          this.filter.city_id = null;
          
          const additionalJobs = await this.action.getJobList(this.filter);
          
          for (const job of additionalJobs.jobs) {
            if (this.rec_jobs.length < 10) {
              const matchingJobs = this.rec_jobs.filter(r_job => r_job.job_id === job.job_id);
              if (matchingJobs.length === 0) {
                job.last_date = this.validate.convertTimestamp(job.last_date);
                this.jobs.push(job);
              }
            }
          }

          for (const job of this.jobs) {
            job.last_date = this.validate.convertTimestamp(job.last_date);
            let param = job.job_url ? job.job_url : '';
            param = param.replace(/[^A-Z0-9]+/ig, "-");
            
            if (param.length > 80) {
              const param_split = param.split("-");
              const id_length = param_split[param_split.length - 1].length;
              param = param.substring(0, 80 - (id_length + 1)) + '-' + param_split[param_split.length - 1];
            }
            
            job.param = param;
          }
          
          this.jobs_loading = false;
          this.getAppliedJobList();
        } else {
          this.jobs_loading = false;
          this.jobs = response.jobs;
          
          for (const job of this.jobs) {
            job.last_date = this.validate.convertTimestamp(job.last_date);
            let param = job.job_url ? job.job_url : '';
            param = param.replace(/[^A-Z0-9]+/ig, "-");
            
            if (param.length > 80) {
              const param_split = param.split("-");
              const id_length = param_split[param_split.length - 1].length;
              param = param.substring(0, 80 - (id_length + 1)) + '-' + param_split[param_split.length - 1];
            }
            
            job.param = param;
          }
          
          this.getAppliedJobList();
        }

        this.filter.limit = this.limit;
      } catch (error) {
        // Handle any errors here
        console.error(error);
      }
    },
    InstitutePrivateJobs(){
      this.insti_jobs_loading = true;
      let token = this.$cookies.get("hire-seeker").token;
      let param = '?limit='+this.institute_meta.limit+'&offset='+this.institute_meta.offset
      this.action.getInstitutePrivateJobs(param,token).then((res) => {
          this.institute_meta.offset = res.meta.offset;
          this.institute_meta.limit = res.meta.limit;
          this.institute_meta.total = res.meta.total;
          this.institute_jobs = res.jobs;
          this.insti_jobs_loading = false;
      });
    },
    CollegePrivateJobs(){
      this.college_jobs_loading = true;
      let token = this.$cookies.get("hire-seeker").token;
      let param = '?limit='+this.college_meta.limit+'&offset='+this.college_meta.offset
      this.action.getCollegePrivateJobs(param,token).then((res) => {
          console.log('--->>', res);
          this.college_meta.offset = res.meta.offset;
          this.college_meta.limit = res.meta.limit;
          this.college_meta.total = res.meta.total;
          this.college_jobs = res.jobs;
          this.college_jobs_loading = false;
      });
    },
    getDate(ts){
        var timestamp = ts * 1000;
        var date = new Date(timestamp);
        return this.pad(date.getDate())+
            "-"+this.pad(date.getMonth()+1)+
            "-"+date.getFullYear()
    },
    pad(n){
      if(n < 10) return '0'+n; else return n;
    },
    getAppliedJobList(){
      this.action.getAppliedJobList(this.user.id, this.$cookies.get("hire-seeker").token).then((res) => {
        this.appliedjobs_loading = false;
        res.applied_jobs.forEach(job => {
          let param = job.job.job_url?job.job.job_url:'';
          param = param.replace(/[^A-Z0-9]+/ig, "-");
          if(param.length > 80){
            let param_split = param.split("-");
            let id_length = param_split[param_split.length-1].length;
            param = param.substring(0, 80-(id_length+1));
            param = param+'-'+param_split[param_split.length-1];
          }
          job.param = param;
          this.appliedJobs.push(job);
        })
        this.checkIfApplied(this.jobs)
        this.checkIfApplied(this.institute_jobs)
      });
    },
    checkIfApplied: function(job_list){
      job_list.forEach(job => {
        const isApplied = this.appliedJobs.some(appliedJob => appliedJob.job_id === job.job_id);
        Vue.set(job, 'applied', isApplied);
      });
    },
    generateJobUrl: function(job_list){
      job_list.forEach(job => {
          job.last_date = this.validate.convertTimestamp(job.last_date);
          let param = job.job_url?job.job_url:'';
          param = param.replace(/[^A-Z0-9]+/ig, "-");
          if(param.length > 80){
            let param_split = param.split("-");
            let id_length = param_split[param_split.length-1].length;
            param = param.substring(0, 80-(id_length+1));
            param = param+'-'+param_split[param_split.length-1];
          }
          job.param = param;
        });
    },
    getStates: async function () {
      await this.action.getStates().then(async (data) => {
        this.locations.push({header: 'Top Metropolitan Cities'})
        await data.states.forEach(async (element) => {
          await element.cities.forEach(city => {
            if(city.top_city == 1){
              this.locations.push({text:city.city_name,id:city.city_id, s_id:city.state_id,group:'Top Metropolitan Cities'})
            }
          });
          this.locations.push({divider:true})
          this.locations.push({header: element.state_name});
          element.cities.forEach(city => {
            if(city.top_city == 0){
              this.locations.push({text:city.city_name,id:city.city_id, s_id:city.state_id,group:element.state_name})
            }
        });
      });
      });
    },
    getRecommendedJobs(e){
      e.target.classList.add('active');
      document.getElementById('pills-applied-tab').classList.remove('active');

      document.getElementById('pills-recommend').classList.add('active');
      document.getElementById('pills-applied').classList.remove('active');
    },
    getAppliedJobs(e){
      e.target.classList.add('active');
      document.getElementById('pills-recommend-tab').classList.remove('active');

      document.getElementById('pills-recommend').classList.remove('active');
      document.getElementById('pills-applied').classList.add('active');
    }
  }
}
</script>

